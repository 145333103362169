import React, {createContext, useReducer, useEffect, useContext, useRef, useState}  from 'react';
import './App.css';
import logo from './logo.svg'
import Reducer from './Reducer';
import Store, {Context} from './Store';
import MyEditor from './MyEditor';
import ResultImage from './ResultImage';

function App() {
  return (
    <Store>
    <div className="App">
      <header className="App-header">
        <h1>ENTENMANNS MAILING LIST HEADER</h1>
        <span> Drag and Drop the background image below </span>
        <br/>
        <span> Click on "Create" </span>
        <br/>
        <span> Click on "Save Image" </span>
      </header>
      <MyEditor />
      <ResultImage /> 
    </div>    
    </Store>
  );
}

export default App;
