import React, { useEffect, useContext, useRef, useState}  from 'react';
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { Context } from './Store'
function MyEditor() {
  const [state, dispatch] = useContext(Context);
  const [image, setImage] = useState('')  
  const dropzoneRef = useRef();
  const handleDrop = dropped => {
    setImage(dropped[0])
  }

  const onClickSave = () => {
    if (dropzoneRef) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = dropzoneRef.current.getImageScaledToCanvas().toDataURL();

      let imageURL;
      fetch(canvas)
        .then(res => res.blob())
        .then(blob => (imageURL = window.URL.createObjectURL(blob)))
        .then(() => dispatch({type: 'SET_IMAGE', payload: imageURL}))
    }
  }

    return (
      <div>
        <Dropzone
          onDrop={handleDrop}
          noClick
          noKeyboard
          style={{ width: '250px', height: '250px' }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
          
              <AvatarEditor width={1000} height={250} image={image} ref={dropzoneRef}/>
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>   
        <button onClick={onClickSave}> CREATE </button>     
      </div>
    )
  }

export default MyEditor;