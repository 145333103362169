import React, { useEffect, useContext, useRef, useState}  from 'react';
import { Context } from './Store'

function ResultImage() {  
  const [state, dispatch] = useContext(Context);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const logoRef = useRef(null);

  useEffect( () => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    const img = imageRef.current
    const logo = logoRef.current
    if (img) {
      img.onload = () => {
        ctx.drawImage(img, 0, 0)
        ctx.fillStyle = "rgba(225,225,225,0.5)";
        ctx.fillRect(canvas.width / 2 - 320 / 2, canvas.height / 2 - 140 / 2, 320, 140);
        ctx.drawImage(logo, canvas.width / 2 - 300 / 2, canvas.height / 2 - 120 / 2, 300, 120)
      }      
    }

  }, [state.image])

  const saveImage = () => {
    const canvas = canvasRef.current

    window.open(canvas.toDataURL('image/png'));
    var gh = canvas.toDataURL('png');

    var a  = document.createElement('a');
    a.href = gh;
    a.download = 'image.png';

    a.click()
  }

  return(
    <div>
      <span> THIS IS THE RESULT </span>
      <button onClick={() => {saveImage()}}> save image </button>
      <br/>
      <canvas ref={canvasRef} width={1000} height={250} />
      <br/>
      
      <img ref={imageRef} src={state.image} className="hidden" />
      <img ref={logoRef} src="./Entenmanns_Logo.png" className="Entenmanns_Logo" className="hidden" />
    </div>
  )
}

export default ResultImage;